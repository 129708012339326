@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  background-color: #000000; /* Full black background */
  color: #e0e0e0; /* Softer text color for readability */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000; /* Full black background */
  padding: 0.5rem; /* Reduce padding to decrease spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content {
  max-width: 800px;
  margin: 1rem auto; /* Reduce margin to decrease spacing */
  padding: 1rem;
  background-size: cover;
  border-radius: 8px;
}

.card {
  background-color: #1a1a1a;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.gradient-text {
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text; /* Vendor prefix for Safari */
  background-clip: text; /* Standard property for compatibility */
  -webkit-text-fill-color: transparent;
}

.App-link {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}

.btn-primary,
.btn-secondary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.btn-primary:hover,
.btn-secondary:hover {
  transform: scale(1.03);
  filter: brightness(0.9);
}

.btn-primary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.App-footer {
  background-color: #000000; /* Full black background */
  padding: 1rem;
  color: #e0e0e0;
}

.full-width-image {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  background-color: rgba(0, 0, 0, 0.7); /* Opaque background */
  color: #e0e0e0;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
}

.blinking-text {
  animation: breathe 5s ease-in-out infinite;
}

@keyframes breathe {
  0%,
  100% {
    color: #ffffff; /* White */
  }
  50% {
    color: #000000; /* Black */
  }
}

.blinking-text-1 {
  animation: breathe1 4s ease-in-out infinite;
  animation-delay: 0s; /* Ensure all animations start at the same time */
}

.blinking-text-2 {
  animation: breathe2 4s ease-in-out infinite;
  animation-delay: 0s; /* Ensure all animations start at the same time */
}

@keyframes breathe1 {
  0%,
  100% {
    color: #ffffff; /* White */
  }
  50% {
    color: #000000; /* Black */
  }
}

@keyframes breathe2 {
  0%,
  100% {
    color: #000000; /* Black */
  }
  50% {
    color: #ffffff; /* White */
  }
}

.logo {
  font-weight: bold;
  font-size: 2rem; /* Adjust size as needed */
  transition: transform 0.6s ease-in-out, color 0.6s ease-in-out;
  color: #ffffff; /* Initial color */
  font-family: "Inter", sans-serif;
}

.logo:hover {
  transform: scale(1.3); /* Increase size by 30% */
  animation: gradientBlink 2s infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Add this at the end of the file */
canvas {
  touch-action: none;
}

.three-viewer-container {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  touch-action: none;
}

.blog-reel-container {
  width: 100%;
  overflow-x: hidden;
  padding: 20px 0;
  position: relative;
}

.blog-reel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-bottom: 20px; /* Add some padding to show the scrollbar */
}

.blog-reel::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.blog-post-card {
  flex: 0 0 auto;
  width: calc(33.333% - 20px);
  min-width: 250px;
  max-width: 300px;
  margin-right: 20px;
  scroll-snap-align: start;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
  height: 200px; /* Reduced height */
  display: flex;
  flex-direction: column;
}

.blog-post-card img {
  width: 100%;
  height: 100px; /* Reduced image height */
  object-fit: cover;
}

.blog-post-card .p-3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-post-card h3 {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
}

.blog-post-card p {
  font-size: 0.75rem;
  line-height: 1.2;
}

.blog-post-card .line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2; /* Add this line for standard property */
}

/* Show part of the fourth card */
.blog-reel::after {
  content: "";
  flex: 0 0 calc(16.666% - 10px);
  min-width: 125px; /* Half of the minimum width of a card */
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.scroll-arrow:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.scroll-arrow.left {
  left: 10px;
}

.scroll-arrow.right {
  right: 10px;
}

.scroll-arrow svg {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scroll-arrow:hover svg {
  opacity: 1;
}

/* Blog Post Modal Styles */
.blog-post-modal {
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-post-modal::-webkit-scrollbar {
  width: 8px;
}

.blog-post-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.blog-post-modal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.blog-post-modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-post-modal .prose {
  max-width: none;
  color: #333;
}

.blog-post-modal .prose img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1.5rem 0;
}

.blog-post-modal .prose h1,
.blog-post-modal .prose h2,
.blog-post-modal .prose h3,
.blog-post-modal .prose h4,
.blog-post-modal .prose h5,
.blog-post-modal .prose h6 {
  color: #1a1a1a;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.blog-post-modal .prose p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.blog-post-modal .prose a {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.2s ease;
}

.blog-post-modal .prose a:hover {
  color: #2c5282;
  text-decoration: underline;
}

.blog-post-modal .prose ul,
.blog-post-modal .prose ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.blog-post-modal .prose li {
  margin-bottom: 0.5rem;
}

.blog-post-modal .prose blockquote {
  border-left: 4px solid #3182ce;
  padding-left: 1rem;
  margin-left: 0;
  color: #4a5568;
  font-style: italic;
}

.blog-post-modal .prose code {
  background-color: #f7fafc;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-size: 0.875em;
}

.blog-post-modal .prose pre {
  background-color: #2d3748;
  color: #e2e8f0;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
}

.blog-post-modal .prose pre code {
  background-color: transparent;
  padding: 0;
  color: inherit;
}

.email-link {
  color: #ffffff;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.email-link:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text:hover,
.App-link:hover,
.email-link:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
